import React from 'react'
import Slider from 'react-slick'

class Footer extends React.Component {

    state = {
        footerInfo: this.props.footers,
        siteName: this.props.siteName
    }

    pacientInfo(...props) {

        const info = props[0].map(item => <li key={item.id}><span>{item.desc}</span></li>)
        return (
            <div className="col-md-12 col-lg-4">
                <h3>Прием пациентов</h3>
                <ul>{info}</ul>
                <div className="address">{props[1].contacts[2].content}</div>
            </div>
        )
    }

    pacientAdmission(...props) {
        const times = props[0].map(item => <li key={item.id}><span>{item.name} - {item.desc}</span></li>)
        return (
            <div className="col-md-12 col-lg-3">
                <h3>Часы приема</h3>
                <ul>{times}</ul>
                <div className="address">
                    <p className="phoneTitle">Предварительная запись и справки по телефону</p>
                    <p className="phoneNumber">
                        <a href={`tel:${props[1].contacts[0].content}`}>{props[1].contacts[0].content}</a>
                    </p>
                </div>
            </div>
        )
    }

    specialist(props) {

        function ProfessionDesc(prgs) {

            const data = prgs.progress

            const settings = {
                dots: false,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                vertical: true,
                verticalSwiping: false,
                autoplay: true,
                autoplaySpeed: 4000,
                adaptiveHeight: true,
                arrows: false
            }

            let items = data.map(item => {
              return (
                <div className="progress-slider__item-desc" key={item.id}>
                  {item.desc}
                    <div>{item.issued}</div>
                </div>
              )
            })

            return (
                <div className="progress-slider">
                    <Slider {...settings}>
                        {items}
                    </Slider>
                </div>
            )
        }

        const items = props.map(item =>

            <div className="owner-info-container-person" key={item.id}>
                <div className="owner-info">
                    <div className="owner-names">
                        <div className="owner-firstName">{item.firstName}</div>
                        <div className="owner-lastName">{item.lastName}</div>
                    </div>
                    <div className="owner-progress">
                        <div className="owner-profession-name">{item.profession}</div>
                    </div>
                </div>
                <div className="owner-img">
                    <figure>
                        <img src={item.img} alt="{item.firstName} {item.lastName}" />
                    </figure>
                </div>
                <div className="owner-profession-desc">
                    <ProfessionDesc progress={item.progress} />
                </div>
            </div>
        )

        return <div className="col-md-12 col-lg-5"><div className="owner-info-container">{items}</div></div>
    }

    render() {

        const site_name = this.state.siteName
        const footer_info = this.state.footerInfo

        let date = new Date()

        return (
            <div className="section">
                <div className="footer">
                    <div className="container">
                        <div className="footer-all-info">
                            <div className="row">
                                {this.pacientInfo(footer_info.pacient_admission.info, site_name)}

                                {this.pacientAdmission(footer_info.pacient_admission.times, site_name)}

                                {this.specialist(footer_info.specialists)}
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <div className="container">
                            <div className="copyright">{date.getFullYear()} | {site_name.name}</div>
                            <div className="creator">
                                <a href="//as-tarabanov.ru" rel='nofollow'>as-tarabanov.ru</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer

import React from 'react'

// import Nav from './Nav'
import Title from './Title'

class Header extends React.Component {
    
    state = {
        site: this.props.info
    }
    
    render() {
        
        const baseInfo = this.state
        
        return (
            <div className="section">
                <div className="header">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-md-9">
                                <div className="navbar">
                                    {/*<Nav />*/}
                                </div>
                            </div>
                            <div className="col-xs-12 col-md-3">
                                <div className="header_phone">
                                    <a href={`tel:${baseInfo.site.contacts[0].content}`}>{baseInfo.site.contacts[0].content}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="title">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-md-6">
                                <Title siteName={baseInfo.site} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Header
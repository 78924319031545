import React from "react"
import Header from "components/Header/Header"
import ServicesTop from 'components/Services/ServicesTop'
import ServicesBottom from 'components/Services/ServicesBottom'
import AboutUs from 'components/About/AboutUs'
import Therapy from 'components/Therapy/Therapy'
import License from 'components/License/License'
import Partners from "components/Partners/Partners";
import Map from "components/Map/Map";
import Article from 'components/Article/Article'
import Footer from "components/Footer/Footer";
import {baseInfo, footers, licenses, partners, services, article} from 'components/const'
import './styles/main.scss'
// import FeedbackForm from "components/Feedback/FeedbackForm";

class App extends React.Component {
    
    render() {
        
        return (
            <React.Fragment>
                
                <Header info={baseInfo.site} />
                
                <div className="section">
                        <div className="services-block">
                    <div className="container">
                            <ServicesBottom serv2={services.block2} />
                            <ServicesTop serv={services.block1} />
                        </div>
                    </div>
                </div>
                
                <AboutUs about={baseInfo.description} />
                
                <Therapy therapy={services.therapy} />
                
                <License license={licenses} />
                
                <Partners partners={partners} />
                
                <Article article={article}/>
                
                {/*<FeedbackForm />*/}
                
                <Map info={baseInfo.site}/>
                
                <Footer footers={footers} siteName={baseInfo.site} />
            
            </React.Fragment>
        )
    }
}

export default App
import React from 'react'

export default function Title(props) {

    const {name} = props.siteName
    const base_name = name.substring(0, name.indexOf('"'))
    const company_name = name.split('"').slice(-2)

    const site_name = <h2>{base_name}<br /><span>"{company_name}"</span></h2>

    return <React.Fragment>{site_name}</React.Fragment>
}

import React from 'react'

export default function AboutUs(props) {
    
    const about = props.about
    
    return (
        <div className="section">
            <div className="about">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-lg-10 col-xl-9 col-xxl-7">
                            <div className="o-about">
                                <h1 className="o-about-title">{about.name}</h1>
                                <div className="o-about-desc">{about.name} {about.info}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
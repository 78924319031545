import React from 'react'
import GetData from '../processors/GetData'


const License = ({license}) => {

    const Items = ({items: licenses}) => (
        <div className="s-media-body">
            <GetData content={licenses} cls='s-media-items'/>
        </div>
    );

    return (
        <div className="section">
            <div className="license">
                <div className="container">
                    <div className="s-media">
                        <Items items={license}/>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default License
import React from 'react'
import GoogleMapReact from 'google-map-react'

const MapArea = ({text}) => <div className="baloon" style={{
    width: '90px',
    height: '90px',
    padding: '15px 10px',
    display: 'inline-flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
    transform: 'translate(-50%, -50%)'
}}
>{text}</div>;

function Map(props) {
    
    const defaultProps = {
        center: {
            lat: 54.733915,
            lng: 20.512100
        },
        zoom: 18
    }
    
    return (
        <div style={{height: '50vh', width: '100%'}}>
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: 'AIzaSyCOrlP4ECUZ3voiRlUUtzRcdPVWvj5CcTQ',
                    language: 'ru',
                    region: 'ru'
                }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
            >
                <MapArea
                    lat={54.733915}
                    lng={20.512100}
                    text={'Гиппократ+'}
                />
            </GoogleMapReact>
        </div>
    )
}

export default Map




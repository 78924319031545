import React from 'react'
import GetData from '../processors/GetData'


function Items(props) {

    const therapies = props.items

    return (
        <div className="therapy-array">
            <GetData content={therapies.content} cls='therapy-items' />
        </div>
    )
}


function Therapy(props) {

    const therapyTitle = props.therapy.name

    return (
        <div className="section">
            <div className="therapy">
                <div className="container">
                    <h3>{therapyTitle}</h3>
                    <Items items={props.therapy} />
                </div>
            </div>
        </div>
    )
}

export default Therapy

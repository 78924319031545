import React from 'react'
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import "react-tabs/style/react-tabs.css";

function Article(props) {
    const articles = props.article
    
    
    
    const titles = articles.map(item =>
        <Tab key={item.id}>
            <h3>{item.title}</h3>
        </Tab>)
    
    const contents = articles.map(item => {
            
            let contents = item.content.split("\n")
            
            const img = item.img ? item.img : null
            const desc = item.desc ? item.desc : null
            
            return (
                <TabPanel key={item.id}>
                    <article>
                        {img ? <figure><img src={img} alt="img" /></figure> : null}
                        <div class={img ? "article-with-img" : null}>
                            
                            <h5><span>{desc ? desc : null}</span></h5>
                            
                            {contents.map(articleItem => <p>{articleItem}</p>)}
                        </div>
                    </article>
                </TabPanel>
            )
        }
    )
    
    
    return (
        <div className="section">
            <div className="article">
                <div className="container">
                    <div className="s-media">
                        
                        <Tabs>
                            <TabList>
                                {titles}
                            </TabList>
                            
                            {contents}
                        </Tabs>
                    
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Article
import React from 'react'

import GetData from '../processors/GetData'

function Items(props) {
    
    const col = (12 / props.items.length)
    
    return (
        <div className="row">
            <GetData content={props.items} cls={`col-xs-12 col-md-${col}`} />
        </div>
    )
}


function ServicesBottom(props) {
    
    const services = props.serv2
    
    return (
        <div className="services-bottom">
            <h3>{services.name}</h3>
            <Items items={services.content}/>
        </div>
    )
}

export default ServicesBottom
import React from 'react'

class GetData extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            content: props.content,
            classes: props.cls ? props.cls : ''
        }
    }
    
    getElementsContent(props) {
        
        const cls = this.state.classes
        
        // console.log(props)
        
        const items = props.map((item, k) =>
                    <div className={`item-${item.k} ${cls}`} key={item.k}>
                        <div className="s-content">
                    
                            {/* titles */}
                            {item.title ? <div className="s-item-title">{item.title}</div> : ''}
                    
                            {/* name */}
                            {item.name ? <div className="s-item-name">{item.name}</div> : ''}
                    
                            {/* intro */}
                            {item.intro ? <div className="s-item-intro">{item.intro}</div> : ''}
                    
                            {/* content */}
                            {item.content ? <div className="s-item-content">{item.content}</div> : ''}
                    
                            {/* images */}
                            {item.link
                                ?
                                <figure className="s-figure-item-link">
                                    <img src={item.link} alt={item.name} />
                                </figure>
                                : ''}
                
                        </div>
                    </div>
        )
        
        
        return <React.Fragment>{items}</React.Fragment>
    }
    
    render() {
        
        const elementContent = this.state.content
        const elementClassName = this.state.classes
        
        const elements = this.getElementsContent(elementContent, elementClassName)
        
        return <React.Fragment>{elements}</React.Fragment>
    }
}

export default GetData
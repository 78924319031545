import React from 'react'
import GetData from '../processors/GetData'


function Items(props) {
    
    const partners = props.items
    
    return (
        <div className="o-partner-content">
            <GetData content={partners} cls='partners-items' />
        </div>
    )
}


function Partners(props) {
    
    return (
        <div className="section">
            <div className="partners">
                <div className="container">
                    <h2 className="o-partners-title">СОТРУДНИЧЕСТВО</h2>
                    <Items items={props.partners} />
                </div>
            </div>
        </div>
    )
}

export default Partners